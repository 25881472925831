import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#e4007f',
                alter_black: '#272727',
                alter_grey: '#626262',
                alter_sky: '#99d1e5'
            },
        },
    },  
})
